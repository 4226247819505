<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- TODO: Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <h1 class="brand-text text-primary ml-1">Stories</h1>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <login header="Hola! 👋" alt-header="Ingresá tu usuario y contraseña" @onLogin="onLogin"></login>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BLink, BImg } from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import { capitalizeFirstLetter } from '../../util/util'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImg,

    VuexyLogo,
    login: () => import('../components/user/loginBox.vue' /* webpackChunkName: "loginbox"		*/)
  },
  metaInfo: {
    title: 'Login',
    titleTemplate: '%s | Ingrea a tu cuenta'
  },
  data: () => ({
    sideImg: require('@/assets/images/pages/login-v2.svg')
  }),
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    }
  },
  methods: {
    onLogin() {
      this.$router.push({ name: 'home' }).catch(() => {})
      this.$noti.info(`Bienvenid@ ${this.$session.user.fullname ? capitalizeFirstLetter(this.$session.user.fullname.split(' ')[0]) : ''} !!!`)
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
