<template>
  <div class="logo">
    <a class="logo__link">
      <img :src="img" alt="Logo" />
    </a>
  </div>
</template>
<script>
export default {
  data() {
    return {
      img: require('@/assets/images/logo/logo32.png')
    }
  }
}
</script>
